import { selectDefaultFilterPriceType } from '@/ducks/filtersOptions';
import isSuitableCabinType from '@/helpers/isSuitableCabinType';
import { FiltersPriceType } from '@/infra/types/common/filters';

import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes, type GetFilterValuesProps } from '../types';
import { getSearchParamsValue, toInt } from '../utils';

const getDefaultValue = ({ searchParams, state }: Pick<GetFilterValuesProps, 'searchParams' | 'state'>) => {
  const cabinType = getSearchParamsValue(FILTER_KEY.cabinType, searchParams) as string;
  return cabinType && isSuitableCabinType(cabinType)
    ? FiltersPriceType.sailorPerNight
    : selectDefaultFilterPriceType(state) || FiltersPriceType.perCabin;
};

const minPrice: FiltersConfigValue<FiltersTypes[FILTER_KEY.priceMin]> = {
  getValue: ({ value }) => toInt(value),
};

const maxPrice: FiltersConfigValue<FiltersTypes[FILTER_KEY.priceMax]> = {
  getValue: ({ value }) => toInt(value),
};

const priceType: FiltersConfigValue<FiltersTypes[FILTER_KEY.priceType]> = {
  getValue: ({ searchParams, state, value }) => {
    const hasValidValue = value && Object.values(FiltersPriceType).includes(value as FiltersPriceType);
    return hasValidValue ? (value as FiltersPriceType) : getDefaultValue({ searchParams, state });
  },
  serializeValue(value, { searchParams, state }) {
    const defaultPriceType = getDefaultValue({ searchParams, state });
    return !value || value === defaultPriceType ? null : value;
  },
};

export { maxPrice, minPrice, priceType };
