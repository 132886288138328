import { type HTMLAttributes, useCallback, useState } from 'react';

import cn from 'classnames';

import type { TOptional } from '@/types/common';

import ShareCopy from '@/components/Icon/ShareCopy';
import { copyToClipboard } from '@/helpers/util';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import { ShareType } from '../constants';

export const ARIA_LABEL = 'Share via copy the url to clipboard';

type TProps = Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> & {
  className?: string;
  isAltIcon?: TOptional<boolean>;
  link: TOptional<string>;
  onClick: TOptional<(type: ShareType) => void>;
};

const ShareLinkSetClipboard = ({
  ['aria-label']: ariaLabel,
  autoFocus,
  className,
  isAltIcon,
  link,
  onClick,
  tabIndex,
  ...restRootProps
}: TProps) => {
  const [isClicked, setIsClicked] = useState<boolean>();

  const clickHandler = useCallback(() => {
    copyToClipboard(link).then(() => onClick?.(ShareType.CLIPBOARD));
    setIsClicked(true);
  }, [link, onClick]);

  const handleKeyDown = useOnKeyDown(clickHandler, { isDefaultPrevented: true });

  return (
    <div
      {...restRootProps}
      className={cn('ShareLinkSet-Item ShareLinkSet-Clipboard', className, { _alt: isAltIcon, _disabled: !link })}
    >
      <button
        aria-label={ariaLabel || ARIA_LABEL}
        autoFocus={autoFocus}
        onClick={clickHandler}
        onKeyDown={handleKeyDown}
        tabIndex={tabIndex || 0}
        value={isClicked ? link : undefined}
      >
        <ShareCopy />
      </button>
    </div>
  );
};

export default ShareLinkSetClipboard;
