import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';
import { getFiltersOptions } from '@/ducks/filtersOptions';

const cabinType: FiltersConfigValue<FiltersTypes[FILTER_KEY.cabinType]> = {
  getValue: ({ state, value }) => {
    const { cabinTypes } = getFiltersOptions(state);
    const cabinType = value && cabinTypes.find((t) => t.code === value)?.code;
    return cabinType || null;
  },
};

export default cabinType;
