import React from 'react';

import cn from 'classnames';

import type { TNullable } from '@/infra/types/common';

import Trash from '@/components/Icon/Trash';
import IconButton from '@/components/elements/IconButton';

import styles from './MarqueeBannerContainer.module.scss';

type TProps = {
  children: React.ReactNode;
  className?: string;
  desktopClearText?: string;
  mobileClearText?: string;
  onClearClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLDivElement>;
  url?: TNullable<string>;
};

const MarqueeBannerContainer = ({
  children,
  className,
  desktopClearText = 'Clear',
  mobileClearText = 'Clear',
  onClearClick,
  onClick,
  url,
}: TProps) => {
  const Node = url ? 'a' : 'div';
  const linkProps = url ? { href: url, target: '_blank' } : undefined;

  return (
    <div className={cn(styles.root, className)}>
      <Node
        className={cn(styles.wrapper, { [styles._clickable as string]: url || onClick })}
        onClick={onClick}
        role="none"
        {...linkProps}
      >
        <div className={styles.content}>{children}</div>
        <div className={styles.content}>{children}</div>
      </Node>

      {onClearClick && (
        <IconButton aria-label={desktopClearText} border className={styles.clearButton} onClick={onClearClick}>
          {desktopClearText && <span className={cn(styles.label, styles._desktop)}>{desktopClearText}</span>}
          {mobileClearText && <span className={cn(styles.label, styles._mobile)}>{mobileClearText}</span>}

          <Trash />
        </IconButton>
      )}
    </div>
  );
};

/*
const useHackToRepaint = (ref: React.RefObject<TWrapper> , deps: React.DependencyList) => {
  useEffect(() => {
    if (ref.current) {
      ref.current.style.display = 'none';

      setTimeout(() => {
        ref.current.style.display = '';
      }, 0);
    }
  }, [deps, ref.current]);
};
*/

export default MarqueeBannerContainer;
