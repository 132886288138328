import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import { getMnvvReservation } from '@/ducks/mnvv/utils';

import { toInt } from '../utils';

const sailors: FiltersConfigValue<FiltersTypes[FILTER_KEY.sailors]> = {
  getValue: ({ searchParams, state, value }) => {
    const options = getFiltersOptions(state);
    const { isMNVV } = getMnvvReservation(searchParams);
    const sailors = toInt(value);

    if (
      !sailors ||
      (sailors === 1 && isMNVV) ||
      sailors < options.minCabinOccupancy ||
      sailors > options.maxCabinCount * options.maxCabinOccupancy
    ) {
      return options.defaultFilters.sailors;
    }

    return sailors;
  },
  serializeValue(value, { state }) {
    const options = getFiltersOptions(state);
    return !value || value.toString() === options?.defaultFilters?.sailors.toString() ? null : value;
  },
};

export default sailors;
