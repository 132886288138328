import React, { type ReactHTML, useMemo } from 'react';

import { isServerSide } from '@/helpers/isServerSide';

type TProps = {
  className?: string;
  content: string;
  id?: string;
  node?: keyof ReactHTML;
};

const HtmlContent = React.memo(({ className, content, id, node: Node = 'div' }: TProps) => {
  const modifiedContent = useMemo(() => {
    if (isServerSide() || !content) return content;

    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;

    tempElement.querySelectorAll(':scope a').forEach((link) => link.setAttribute('tabindex', '0'));

    return tempElement.innerHTML;
  }, [content]);

  return <Node className={className} dangerouslySetInnerHTML={{ __html: modifiedContent }} id={id} />;
});

HtmlContent.displayName = 'HtmlContent';

export default HtmlContent;
