import cn from 'classnames';

import Svg, { type TSvgBaseAttrs } from '@/components/Svg';

import './style.scss';

// Avoid using the "svgClassName" property!
// If you change this property, ReactSVG will recreate an instance of the svg element and things like "transition" will not work.

export type TIconBaseAttrs = Omit<TSvgBaseAttrs, 'src'>;

const IconBase = ({ className, ...props }: TSvgBaseAttrs) => (
  <Svg className={cn('Icon IconBase', className)} {...props} />
);

export default IconBase;
