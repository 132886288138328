import cn from 'classnames';

import Svg from '@publicImages/svg/share-copy.svg';

import IconBase, { type TIconBaseAttrs } from '@/components/Icon/Base';

const ShareCopy = ({ className, ...restProps }: TIconBaseAttrs) => (
  <IconBase className={cn('ShareCopy', className)} src={Svg} {...restProps} />
);

export default ShareCopy;
