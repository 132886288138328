import type { TCurrencyCode } from '@/types';

import { CURRENCY_CODE_COOKIE, DEFAULT_CURRENCY_CODE } from '@/constants/settings';
import { selectSupportedCurrencies } from '@/ducks/common/lookup/selectors';
import { selectDefaultCurrencyCode } from '@/ducks/common/selectors';
import { AGENCY_CURRENCY_COOKIE } from '@/ducks/fm/constants';
import { selectAgencyCurrencies, selectIsAgencyDataAvailable } from '@/ducks/fm/selectors';
import { calculateAgencyCurrencyCode } from '@/ducks/fm/setters';
import { selectIsMnvv, selectMnvvCurrencyCode } from '@/ducks/mnvv/selectors';
import { getMnvvReservation } from '@/ducks/mnvv/utils';
import { setStoredValue } from '@/helpers/util';

import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '../types';

type ConfigValue = FiltersConfigValue<FiltersTypes[FILTER_KEY.currencyCode]>;

const normalizeValue = (inputValue: null | string | string[]) => {
  let value = inputValue;
  if (typeof inputValue === 'string') {
    value = inputValue.toUpperCase();
  } else if (Array.isArray(inputValue)) {
    value = inputValue[0]?.toUpperCase() || null;
  }

  return value;
};

const onValueUpdate: ConfigValue['onValueUpdate'] = ({ getCookie, setCookie, state, value: updatedValue }) => {
  const value = updatedValue || (selectDefaultCurrencyCode(state) as TCurrencyCode);
  setStoredValue('cmsCurrencyBroadcast', value);

  if (getCookie(CURRENCY_CODE_COOKIE) !== value.toString()) {
    setCookie(CURRENCY_CODE_COOKIE, value, { path: '/' });
  }

  if (selectIsAgencyDataAvailable(state) && getCookie(AGENCY_CURRENCY_COOKIE) !== value.toString()) {
    setCookie(AGENCY_CURRENCY_COOKIE, value, { path: '/' });
  }
};

const currencyCodeConfig: ConfigValue = {
  explicit: true,
  getValue: (props) => {
    const { getCookie, searchParams, state } = props;
    const value = normalizeValue(props.value);
    const supportedCurrencies = selectSupportedCurrencies(state);
    const currencyCodeFromCookie = getCookie(CURRENCY_CODE_COOKIE) as TCurrencyCode;
    const ipBasedCurrencyCode = selectDefaultCurrencyCode(state) as TCurrencyCode;

    // MNVV
    if (getMnvvReservation(searchParams).isMNVV) {
      return selectMnvvCurrencyCode(state) as TCurrencyCode;
    }

    // FM
    if (selectIsAgencyDataAvailable(state)) {
      return calculateAgencyCurrencyCode(props) as TCurrencyCode;
    }

    // REGULAR
    let currencyCode = supportedCurrencies.includes(value as string) ? (value as TCurrencyCode | null) : null;

    if (!currencyCode && supportedCurrencies.includes(currencyCodeFromCookie)) {
      return currencyCodeFromCookie;
    }

    if (!currencyCode) {
      currencyCode = ipBasedCurrencyCode;
    }

    return currencyCode || DEFAULT_CURRENCY_CODE;
  },
  isValueValidForURL: ({ state, value: inputValue }) => {
    if (Array.isArray(inputValue)) {
      return false;
    }

    const value = normalizeValue(inputValue);
    const isMnvv = selectIsMnvv(state);

    // MNVV
    if (isMnvv) {
      return selectMnvvCurrencyCode(state) === value;
    }

    // FM
    if (selectIsAgencyDataAvailable(state)) {
      return selectAgencyCurrencies(state)?.includes(value as TCurrencyCode);
    }

    // REGULAR
    return !value || selectSupportedCurrencies(state).includes(value as TCurrencyCode);
  },
  onValueInit: onValueUpdate,
  onValueUpdate,
};

export default currencyCodeConfig;
