import { useEffect, useState } from 'react';

import type { TOptional } from '@/types/common';

import { memoizedShortenUrl } from '@/ducks/memoizedApiCalls/shortenUrl';
import { isProdEnv } from '@/helpers/util/env';

const useShortLink = (link: TOptional<string>, enabled: TOptional<boolean>) => {
  const [shortLink, setShortLink] = useState<string | undefined>();

  useEffect(() => {
    const shortenLink = async (url: string) => {
      try {
        const result = await memoizedShortenUrl(url);
        setShortLink(result);
      } catch (error) {
        if (!isProdEnv()) console.error('Unable to shorten link:', error);
      }
    };

    if (enabled && link) {
      shortenLink(link.trim());
    }
  }, [enabled, link]);

  return enabled ? shortLink : link;
};

export default useShortLink;
