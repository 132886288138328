import { type HTMLAttributes, useCallback, useMemo } from 'react';

import cn from 'classnames';

import type { TOptional } from '@/types/common';

import ShareEmail from '@/components/Icon/ShareEmail';
import { useUIResourcePool } from '@/ducks/common/resources';
import { makeEmailLink } from '@/helpers/social/outer';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import { ShareType } from '../constants';

export const UI_RESOURCE_POOL = {
  body: 'SocialLinks.mailBody',
  subject: 'SocialLinks.mailSubject',
} as const;

export const ARIA_LABEL = 'Share via email';

type TProps = Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> & {
  className?: string;
  isAltIcon?: TOptional<boolean>;
  link: TOptional<string>;
  onClick: TOptional<(type: ShareType) => void>;
  templateBody?: TOptional<string>;
  templateSubject?: TOptional<string>;
};

const ShareLinkSetEmail = ({
  ['aria-label']: ariaLabel,
  autoFocus,
  className,
  isAltIcon,
  link,
  onClick,
  tabIndex,
  templateBody,
  templateSubject,
  ...restRootProps
}: TProps) => {
  const { body, subject } = useUIResourcePool(UI_RESOURCE_POOL);

  const href = useMemo(() => {
    if (link) {
      return templateBody && templateSubject
        ? makeEmailLink(templateSubject, templateBody.replace('{shareLink}', link))
        : makeEmailLink(subject, `${body} ${link}`);
    }
  }, [body, link, subject, templateBody, templateSubject]);

  const clickHandler = useCallback(() => {
    if (href) window.open(href, '_blank');
    onClick?.(ShareType.EMAIL);
  }, [href, onClick]);

  const handleKeyDown = useOnKeyDown(clickHandler, { isDefaultPrevented: true });

  return (
    <div
      {...restRootProps}
      className={cn('ShareLinkSet-Item ShareLinkSet-Email', className, { _alt: isAltIcon, _disabled: !link })}
    >
      <button
        aria-label={ariaLabel || ARIA_LABEL}
        autoFocus={autoFocus}
        onClick={clickHandler}
        onKeyDown={handleKeyDown}
        tabIndex={tabIndex || 0}
      >
        <ShareEmail />
      </button>
    </div>
  );
};

export default ShareLinkSetEmail;
