import cn from 'classnames';

import Svg from '@publicImages/svg/share-x.svg';

import IconBase, { type TIconBaseAttrs } from '@/components/Icon/Base';

const ShareXTwitter = ({ ariaLabel, className, ...restProps }: TIconBaseAttrs) => (
  <IconBase className={cn('ShareXTwitter', className)} src={Svg} {...restProps} />
);

export default ShareXTwitter;
