import isEmpty from 'lodash/isEmpty';

import type { TLookupShip } from '@/ducks/common/lookup/types';

import { selectLookup } from '@/ducks/common/selectors';
import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';

import { toStringArray } from '../utils';

const ships: FiltersConfigValue<FiltersTypes[FILTER_KEY.ships]> = {
  getValue: ({ state, value }) => {
    const data = selectLookup(state).ships;
    return toStringArray(value).reduce<TLookupShip[]>((ships, id) => {
      const ship = data.find((ship) => ship.id === id);
      return ship ? [...ships, ship] : ships;
    }, []);
  },
  serializeValue(value) {
    return !isEmpty(value) ? value.map(({ id }) => id.toString()) : null;
  },
};

export default ships;
