import { isAfter, isBefore, isValid } from 'date-fns';
import isEqual from 'lodash/isEqual';

import type { RootState } from '@/store';

import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';
import { getSearchParamsValue } from '@/ducks/filters/utils';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import { selectMnvvVoyage } from '@/ducks/mnvv/selectors';
import { calculateMaxDate, getMnvvReservation } from '@/ducks/mnvv/utils';
import { format, parse } from '@/helpers/util/dateUtil';

type RawDate = null | string;

const date = (date: RawDate, minDate: Date | null, maxDate: Date | null, defaultDate: RawDate) => {
  const d = parse(date || defaultDate);

  if (minDate && (!d || !isValid(d) || isBefore(d, minDate))) {
    return minDate;
  }

  if (maxDate && d && isAfter(d, maxDate)) {
    return maxDate;
  }

  return isValid(d) ? d : null;
};

const getMinMaxDate = (state: RootState, searchParams: URLSearchParams) => {
  const { isMNVV } = getMnvvReservation(searchParams);
  const options = getFiltersOptions(state);
  const voyage = selectMnvvVoyage(state);

  const minDate = parse(options.minDate);
  let maxDate = parse(options.maxDate);

  if (isMNVV && voyage?.bookedDate) {
    maxDate = calculateMaxDate(voyage?.bookedDate) || maxDate;
  }

  return { maxDate: isValid(maxDate) ? maxDate! : null, minDate: isValid(minDate) ? minDate! : null };
};

const dates = (state: RootState, searchParams: URLSearchParams, fromDate: null | string, toDate: null | string) => {
  const { maxDate, minDate } = getMinMaxDate(state, searchParams);

  const options = getFiltersOptions(state);
  const from = date(fromDate, minDate, maxDate, options.defaultStartDate);
  const to = date(toDate, minDate, maxDate, options.defaultEndDate);

  if (from && to && (isBefore(from, to) || isEqual(from, to))) {
    return { fromDate: format(from) as string, toDate: format(to) as string };
  }

  return {
    fromDate: options.defaultStartDate || null,
    toDate: options.defaultEndDate || null,
  };
};

const fromDate: FiltersConfigValue<FiltersTypes['dateFrom']> = {
  getValue: ({ searchParams, state, value }) => {
    return dates(state, searchParams, value, getSearchParamsValue(FILTER_KEY.dateTo, searchParams)).fromDate;
  },
};

const toDate: FiltersConfigValue<FiltersTypes['dateTo']> = {
  getValue: ({ searchParams, state, value }) => {
    return dates(state, searchParams, getSearchParamsValue(FILTER_KEY.dateFrom, searchParams), value).toDate;
  },
};

export { fromDate, toDate };
