import { useEffect, useRef } from 'react';

import type { TOptional } from '@/types/common';

export type TListener = () => void;

// The "data-use-click-outside-skip-any-child" attribute can be used to skip any clicks from any child

export const isInsideEvent = (node: HTMLElement, event: MouseEvent): TOptional<boolean> => {
  const rect = node?.getBoundingClientRect();
  if (rect && event) {
    const { clientX: x, clientY: y } = event;
    const { bottom, left, right, top } = rect;
    return left <= x && x <= right && top <= y && y <= bottom;
  }
};

export const isSkipChild = (node: HTMLElement, eventTarget: EventTarget): boolean => {
  const value = node.dataset.useClickOutsideSkipAnyChild;
  return value === 'true' && node.contains(eventTarget as HTMLElement);
};

const useClickOutside = <E extends HTMLElement = HTMLElement>(
  listener: TListener,
  disable?: boolean,
  includeFirstEvent?: boolean,
): React.MutableRefObject<E | null> => {
  const elementRef = useRef<E | null>(null);
  const listenerRef = useRef<TListener>();

  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    if (elementRef.current && !disable) {
      let started: boolean;
      const handler = (event: MouseEvent) => {
        if (!includeFirstEvent && !started) {
          started = true;
        } else {
          const node = elementRef.current;
          const fn = listenerRef.current;
          if (fn && node && !isInsideEvent(node, event) && !isSkipChild(node, event.target!)) {
            fn();
          }
        }
      };
      document.addEventListener('click', handler);
      return () => document.removeEventListener('click', handler);
    }
  }, [disable, includeFirstEvent]);

  return elementRef;
};

export default useClickOutside;
