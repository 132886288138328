export const makeFacebookLink = (url: string) =>
  `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;

export const makeTwitterLink = (text: string, hashtags?: string, url?: string) =>
  `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}${
    hashtags ? `&hashtags=${encodeURIComponent(hashtags)}` : ''
  }${url ? ` ${encodeURIComponent(url)}` : ''}`;

export const makeEmailLink = (subject: string, body: string) =>
  `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
