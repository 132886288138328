import React from 'react';

import Icon from '@publicImages/svg/circle-arrow.svg?icon';

import styles from './MarqueBannerContent.module.scss';

type TProps = {
  className?: string;
  description?: string;
  promoIcon?: string;
  promoText?: string;
  showIcon?: boolean;
  title?: string;
};

const MarqueeBannerContent = ({ description, promoIcon, promoText, showIcon, title }: TProps) => (
  <>
    {promoText && (
      <div className={styles.promo}>
        {promoIcon && (
          <span aria-label="" className={styles.promoIcon} role="img">
            {promoIcon}
          </span>
        )}
        <span className={styles.promoText}>{promoText}</span>
      </div>
    )}

    {title && <span className={styles.title}>{title}</span>}
    {title && description && <span className={styles.separator}>|</span>}
    {description && <span className={styles.description}>{description}</span>}
    {showIcon && <Icon className={styles.icon} />}
  </>
);

export default MarqueeBannerContent;
