import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';

import type { VoyagesDataRegion } from '@/infra/types/voyageInfo/package';
import type { RootState } from '@/store';

import {
  selectCruisesCommonVoyagesDataRegions,
  selectMapPackageCodeToRegionIds,
  selectMapPortOfCallCodeToRegionIds,
} from '@/ducks/cruisesCommon/selectors';
import { FILTER_KEY, type FiltersConfigValue, type FiltersKey, type FiltersTypes } from '@/ducks/filters/types';
import uniq from '@/helpers/uniq';

import { getSearchParamsValue, toStringArray } from '../utils';

const serializeValue: FiltersConfigValue<string[]>['serializeValue'] = (value) => (!isEmpty(value) ? value : null);

const createPackagesReducer = (selectedRegions: string[]) => (result: string[], region: VoyagesDataRegion) => {
  return selectedRegions.includes(region.id)
    ? [...result, ...region.packages.reduce<string[]>((ids, { id }) => (id ? [...ids, id] : ids), [])]
    : result;
};

const createPortsReducer = (selectedRegions: string[]) => (result: string[], region: VoyagesDataRegion) => {
  const ports = concat(...Object.values(region.portsOfCall));
  return selectedRegions.includes(region.id) ? [...result, ...ports.map(({ code }) => code)] : result;
};

const createItemsGetValue =
  (
    regionsKey: FiltersKey,
    selector: (state: RootState) => Record<string, string[]>,
    createReducer: (regionsIds: string[]) => (result: string[], region: VoyagesDataRegion) => string[],
  ): FiltersConfigValue<string[]>['getValue'] =>
  ({ searchParams, state, value }) => {
    const mapItemToRegionIds = selector(state);
    const items = toStringArray(value);
    const regionsFromItems = uniq(
      items.reduce<string[]>(
        (regionsIds, itemId) =>
          mapItemToRegionIds[itemId] ? [...regionsIds, ...mapItemToRegionIds[itemId]!] : regionsIds,
        [],
      ),
    );
    const regions = toStringArray(getSearchParamsValue(regionsKey, searchParams));
    const regionsWithoutItems = without(regions, ...regionsFromItems); // aka "all selected"

    if (regionsWithoutItems.length === 0) {
      return items;
    }

    return selectCruisesCommonVoyagesDataRegions(state).reduce<string[]>(createReducer(regionsWithoutItems), items);
  };

const createRegionsGetValue =
  (
    itemsKey: FiltersKey,
    selector: (state: RootState) => Record<string, string[]>,
  ): FiltersConfigValue<string[]>['getValue'] =>
  ({ searchParams, state, value }) => {
    const mapItemToRegionIds = selector(state);
    const regionsIds = toStringArray(getSearchParamsValue(itemsKey, searchParams)).reduce<string[]>(
      (regionsIds, itemId) =>
        mapItemToRegionIds[itemId] ? [...regionsIds, ...mapItemToRegionIds[itemId]!] : regionsIds,
      [],
    );

    return uniq([...regionsIds, ...toStringArray(value)]);
  };

export const destPackages: FiltersConfigValue<FiltersTypes[FILTER_KEY.destPackages]> = {
  getValue: createItemsGetValue(FILTER_KEY.destPackagesRegions, selectMapPackageCodeToRegionIds, createPackagesReducer),
  serializeValue,
};

export const destPackagesRegions: FiltersConfigValue<FiltersTypes[FILTER_KEY.destPackagesRegions]> = {
  getValue: createRegionsGetValue(FILTER_KEY.destPackages, selectMapPackageCodeToRegionIds),
  serializeValue,
};

export const destPorts: FiltersConfigValue<FiltersTypes[FILTER_KEY.destPorts]> = {
  getValue: createItemsGetValue(FILTER_KEY.destPortsRegions, selectMapPortOfCallCodeToRegionIds, createPortsReducer),
  serializeValue,
};

export const destPortsRegions: FiltersConfigValue<FiltersTypes[FILTER_KEY.destPortsRegions]> = {
  getValue: createRegionsGetValue(FILTER_KEY.destPorts, selectMapPortOfCallCodeToRegionIds),
  serializeValue,
};
