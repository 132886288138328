import cn from 'classnames';

import Svg from '@publicImages/svg/share-email.svg';

import IconBase, { type TIconBaseAttrs } from '@/components/Icon/Base';

const ShareEmail = ({ className, ...restProps }: TIconBaseAttrs) => (
  <IconBase className={cn('ShareEmail', className)} src={Svg} {...restProps} />
);

export default ShareEmail;
