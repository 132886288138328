import React from 'react';

import TrashImg from '@publicImages/svg/trash.svg';

import Svg from '@/components/Svg';

const Trash = () => (
  <span className="Icon Trash">
    <Svg src={TrashImg} />
  </span>
);

export default Trash;
