import React, { type ComponentProps } from 'react';

import MarqueeBannerContainer from '@/components/MarqueeBanner/Container';
import MarqueeBannerContent from '@/components/MarqueeBanner/Content';

type TProps = Omit<ComponentProps<typeof MarqueeBannerContainer>, 'children'> &
  Omit<ComponentProps<typeof MarqueeBannerContent>, 'showIcon'>;

const MarqueeBanner = ({
  description,
  desktopClearText,
  mobileClearText,
  onClearClick,
  onClick,
  promoIcon,
  promoText,
  title,
  url,
}: TProps) => (
  <MarqueeBannerContainer
    desktopClearText={desktopClearText}
    mobileClearText={mobileClearText}
    onClearClick={onClearClick}
    onClick={onClick}
    url={url}
  >
    <MarqueeBannerContent
      description={description}
      promoIcon={promoIcon}
      promoText={promoText}
      showIcon={!!(url || onClick)}
      title={title}
    />
  </MarqueeBannerContainer>
);

export default MarqueeBanner;
