import isEmpty from 'lodash/isEmpty';

import type { TFiltersDuration } from '@/infra/types/common/filters';

import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';
import { getFiltersOptions } from '@/ducks/filtersOptions';

import { toIntArray } from '../utils';

const durations: FiltersConfigValue<FiltersTypes[FILTER_KEY.durations]> = {
  getValue: ({ state, value }) => {
    const durationObjects = getFiltersOptions(state)?.durationOptions;

    return toIntArray(value).reduce<TFiltersDuration[]>((result, durationDays) => {
      const durationObject = durationObjects?.find((duration) => duration.min === durationDays);
      return durationObject ? [...result, durationObject] : result;
    }, []);
  },
  serializeValue(value) {
    return !isEmpty(value) ? value.map(({ min }) => min.toString()) : null;
  },
};

export default durations;
