import { type HTMLAttributes, useCallback, useMemo } from 'react';

import cn from 'classnames';

import type { TOptional } from '@/types/common';

import ShareXTwitter from '@/components/Icon/ShareXTwitter';
import { useUIResourcePool } from '@/ducks/common/resources';
import { makeTwitterLink } from '@/helpers/social/outer';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import { ShareType } from '../constants';

export const UI_RESOURCE_POOL = {
  hashTag: 'SocialLinks.twitterHashtags',
  text: 'SocialLinks.twitterText',
} as const;

export const ARIA_LABEL = 'Share via Twitter';

type TProps = Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> & {
  className?: string;
  isAltIcon?: TOptional<boolean>;
  link: TOptional<string>;
  onClick: TOptional<(type: ShareType) => void>;
  template?: TOptional<string>;
};

const ShareLinkSetXTwitter = ({
  ['aria-label']: ariaLabel,
  autoFocus,
  className,
  isAltIcon,
  link,
  onClick,
  tabIndex,
  template,
  ...restRootProps
}: TProps) => {
  const { hashTag, text } = useUIResourcePool(UI_RESOURCE_POOL);

  const url = useMemo(() => {
    if (link) {
      return template ? makeTwitterLink(template.replace('{shareLink}', link)) : makeTwitterLink(text, hashTag, link);
    }
  }, [hashTag, link, template, text]);

  const clickHandler = useCallback(() => {
    if (url) window.open(url, '_blank');
    onClick?.(ShareType.X_TWITTER);
  }, [onClick, url]);

  const handleKeyDown = useOnKeyDown(clickHandler, { isDefaultPrevented: true });

  return (
    <div
      {...restRootProps}
      className={cn('ShareLinkSet-Item ShareLinkSet-XTwitter', className, { _alt: isAltIcon, _disabled: !link })}
    >
      <button
        aria-label={ariaLabel || ARIA_LABEL}
        autoFocus={autoFocus}
        onClick={clickHandler}
        onKeyDown={handleKeyDown}
        tabIndex={tabIndex || 0}
      >
        <ShareXTwitter />
      </button>
    </div>
  );
};

export default ShareLinkSetXTwitter;
