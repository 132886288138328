import { forwardRef } from 'react';

import * as Primitive from '@radix-ui/react-dialog';
import cn from 'classnames';

import CrossIcon from '@publicImages/svg/close-without-bg.svg?static';

import classes from './Dialog.module.scss';

export { Close, Description, Root, Title, Trigger } from '@radix-ui/react-dialog';

const focusVisibleTrigger = (e: Event) => {
  e.preventDefault();
  const elem = e.currentTarget as Element | null;
  const triggers = document.querySelectorAll<HTMLElement>(`[aria-controls="${elem?.id}"`);
  for (const node of triggers) {
    if (node.checkVisibility()) {
      node.focus();
      break;
    }
  }
};

export const Content = forwardRef<
  React.ElementRef<typeof Primitive.Content>,
  React.ComponentPropsWithoutRef<typeof Primitive.Content> & {
    closeClassName?: string;
    closeSlot?: React.ReactNode;
    containerClassName?: string;
    multipleTriggers?: boolean;
    overlayClassName?: string;
  }
>(
  (
    {
      children,
      className,
      closeClassName,
      closeSlot,
      containerClassName,
      multipleTriggers,
      overlayClassName,
      title,
      ...props
    },
    ref,
  ) => {
    return (
      <Primitive.Portal>
        <Primitive.Overlay className={cn(classes.overlay, overlayClassName)} />
        <Primitive.Content
          className={cn(classes.fixedContainer, containerClassName)}
          onCloseAutoFocus={multipleTriggers ? focusVisibleTrigger : undefined}
          {...props}
          ref={ref}
        >
          {closeSlot ? (
            closeSlot
          ) : (
            <Primitive.Close
              aria-label={`Close ${title} dialog`}
              className={cn(classes.close, closeClassName)}
              tabIndex={0}
            >
              <CrossIcon />
            </Primitive.Close>
          )}
          <div className={cn(classes.content, className)}>{children}</div>
        </Primitive.Content>
      </Primitive.Portal>
    );
  },
);
Content.displayName = 'DialogContent';
