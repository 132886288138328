import memoize from 'lodash/memoize';

import { shortenUrl } from '@/helpers/api/app';

export const memoizedShortenUrl = memoize(async (url) => {
  try {
    return await shortenUrl(url);
  } catch (error) {
    memoizedShortenUrl.cache.delete(url);
    throw new Error(`Unable to shorten link: ${url}, ${String(error)}`);
  }
});
